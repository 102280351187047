<div class="back-navigation">
  <button class="back">◁ Back to category</button>
</div>

<div class="box">
  <ng-container>
    <div class="text">
      <div class="title">{{ title }}</div>
      <div class="description">{{description ? description :  "Scan & Play"}}</div>
    </div>
    <img class="qrImg" [src]="qrSrc">
    <img class="image" [src]="imgSrc" alt="">
  </ng-container>
</div>
