import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, switchMap } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { NgForOf, NgIf } from '@angular/common';
import { ProductPreviewComponent } from '../product-preview/product-preview.component';
import { IMenuCategory, IProducts, ISubCategories, ISubCategoriesWithProducts } from '@app/models';
import { NewMenuStorageService } from '@app/services/new-menu-storage.service';
import { UserActivityClickTrackingDirective } from '@core';

@UntilDestroy()
@Component({
  selector: 'app-menu-category',
  standalone: true,
  templateUrl: './menu-category.component.html',
  imports: [
    NgIf,
    ProductPreviewComponent,
    NgForOf,
    UserActivityClickTrackingDirective,
  ],
  styleUrls: ['./menu-category.component.scss'],
})
export class MenuCategoryComponent implements OnInit {
  public subCategories: ISubCategoriesWithProducts[] = [];
  public isSticky = false;
  @Input() category!: IMenuCategory;
  @Input() isSolo!: boolean;
  @Output() closeCategory = new EventEmitter<boolean>();
  @Output() openProduct = new EventEmitter<IProducts>();

  constructor(
    private readonly storage: NewMenuStorageService,
    private readonly element: ElementRef,
  ) { }

  ngOnInit(): void {
    this.initSubCategories();
  }

  @HostListener('scroll', ['$event'])
  onScroll(): void {
    this.isSticky = this.element.nativeElement.scrollTop >= 20;
  }

  public emitClose(): void {
    this.subCategories = [];
    this.closeCategory.emit(true);
  }

  showProduct(product: IProducts): void {
    this.openProduct.emit(product);
  }

  private initSubCategories(): void {
    this.storage.getCategorySubCategories(this.category.id).pipe(
      switchMap((subCategories: ISubCategories[]) => {
        const subCategoryObservables = subCategories.map((subCategory: ISubCategories) => {
          return this.getProducts(subCategory.id).pipe(
            map((products: IProducts[]) => (
              { ...subCategory, products }
            )),
          );
        });

        return forkJoin(subCategoryObservables);
      }),
      untilDestroyed(this),
    ).subscribe((subCategories) => {
      this.subCategories = subCategories;
    });
  }

  private getProducts(categoryID: number): Observable<IProducts[]> {
    return this.storage.getCategoryProducts(categoryID).pipe(
      untilDestroyed(this),
    );
  }
}
