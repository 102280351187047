<div class="box-wrapper" xmlns="http://www.w3.org/1999/html" xmlns="http://www.w3.org/1999/html">

  <div class="header">
    <div class="table-name">{{ 'serviceCenter.table' | translate }} {{ tableName }}</div>
  </div>

<app-kelseys-sections-grid appActivitySection="SectionsGrid"></app-kelseys-sections-grid>

  <div class="box-content">
    <div *ngIf="wifiName" class="box-content__wrapper">
      <div class="icon">
        <img src="/themes/kelseys/assets/images/wifi.svg" alt=""/>
      </div>
      <div class="info">
        <div class="network">
          <span class="network__title"> {{ 'serviceCenter.wifi.name' | translate }}: </span>
          <span class="name">{{ wifiName }}</span>
        </div>
        <div *ngIf="wifiPassword" class="network">
          <span class="network__title"> {{ 'serviceCenter.wifi.pass' | translate }}: </span>
          <span class="password"> {{ wifiPassword }} </span>
        </div>
      </div>
    </div>
    <div class="box-content__wrapper">
      <div class="icon">
        <img src="/themes/kelseys/assets/images/battery.svg" alt=""/>
      </div>
      <div class="info">
        <div class="network">
          <span class="network__title"> {{ 'serviceCenter.chargeDevice.title' | translate }}</span>
          <span class="name" [innerHTML]="'serviceCenter.chargeDevice.wired' | translate"></span>
        </div>
      </div>
    </div>
  </div>
</div>
