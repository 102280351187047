<div
  *ngIf="!isSolo"
  class="back-navigation"
  (click)="emitClose()"
  coreUserActivityClickTracking="CloseCategory"
>
  <img src="/images/back.svg" alt="">
</div>
<div class="header" *ngIf="!isSolo">
  <div class="category-name">{{ category.categoryName }}</div>
</div>
<div class="sub_categories" [class.solo]="isSolo">
  <div class="sub_category" *ngFor="let subCategory of subCategories">
    <h3>{{subCategory.categoryName}}</h3>
    <div class="sub_category__products">
      <app-product-preview
        class="product"
        *ngFor="let product of subCategory.products"
        [product]="product"
      ></app-product-preview>
    </div>
  </div>
</div>
